:root {
  --Blue:#df692f;
  --Blue:#EC9454;
  --White:#F2F0EF;
  --White1:#ffefd9;
}
/* --Blue:#2a4a5b;
--White:#F2F0EF;
--White1:#e8f1f7; */

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 100%;
  scroll-behavior:smooth;
  font-family: "Roboto", sans-serif;
}


/* navbar */
nav{
  z-index: 1;
  width:100%;
  height:70px;
  opacity: 0.9;
  position: fixed ;
  background-color: var(--Blue);
  display: flex;
  backdrop-filter: blur(10px); /* Blur effect at the top */
  -webkit-backdrop-filter: blur(10px); 
  justify-content: space-between;
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); 
}

.links .nav-links{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
} 

ul li{
  list-style: none;
  margin: 10px;
}
a{
  text-decoration: none;
  color:var(--White);
  font-size: 20px;
}
a:hover{
  color:#5d95b4;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img{
  background-color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin-left: 100px;
  width:70px;
}

.cont1{
  height:100vh;
  position: relative;
  background-color: var(--White2);
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont1 h1{
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  transform:translate(-50%,-50%);
  font-style: normal;
  color:var(--White);
}
.mainHead{
  background-color: #e3916870;
  width:60vw;
  height:20vh;
}
.cont1::before{
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./Images/photorealistic-perspective-wood-logs-timber-industry_23-2151239283.avif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.7;
}
.container2{
  display: flex;
  width: 100vw;
  height: 70vh;
  justify-content: center;
  align-items: center;
}
.container21{
  padding:100px 50px;
  background-color: var(--Blue);
  color:var(--White);
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  font-size: 25px;
  font-weight: 100;

}
.container22{
  width:40vw;
  background-color: var(--White1);
  height:245px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.footer{
  Width:100vw;
  background-color: var(--Blue);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color:var(--White);
  font-family: "Roboto", sans-serif;
}
.footerLogo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.footerLogo img{
  width:200px;
  margin-bottom: 20px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 50px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}
.product-list{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 350px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.product-name {
  font-size: 18px;
  margin-top: 10px;
}

.product-description {
  font-size: 14px;
  color: #555;
  margin:20px;
}

.product-price {
  font-size: 20px;
  color: #333;
  margin-top: 10px;
}

.cont3{
  margin:100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont3 h1{
font-size: 40px;
margin-bottom: 20px;
color:var(--Blue);
}

.conatiner4{
  Width:100vw;
  background-image: url(./Images/photorealistic-perspective-wood-logs-timber-industry_23-2151239283.avif);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.container41{
  clip-path: polygon(0 0, 100% 0, 76% 100%, 0% 100%);
  clip-path: polygon(13% 0, 100% 0%, 86% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0, 100% 50%, 75% 100%, 0 100%, 0% 50%);
  background-color: #dddddd4e;
  padding:170px;
  top: 0%;
  width:100vw;
}
.container41Text{
  margin:20px;
  font-size: 19px;
  color:var(--White);
}
.container41Text h1{
  font-size: 40px;
}
.container42{
  width:35vw;
  background-color: #fff;
}

.container5{
  display: flex;
  margin:40px 0;
  justify-content: center;
  align-items: center;
}
.container51{
  padding:100px 50px;
  background-color: var(--Blue);
  color:var(--White);
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  font-size: 25px;
  font-weight: 100;
}
.container52{
  width:40vw;
  background-color: var(--White1);
  height:245px;
  padding:20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerLogo img{
  padding: 10px;
  border-radius: 100%;
  background-color: #fff;
}

@media only screen and (max-width: 992px) {
  .container22{
    width:50vw;
  }
  }


@media (max-width: 768px) {
  .navbar {
    overflow: hidden;
  }
  .cont1::before{
    background-position: center;
    background-attachment: scroll;
  }
  .nav-links {
    height:100%;
    width:100%;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    gap: 3px;
  }
  .logo img{
    margin-left: 10px;
    width:60px;
  }
  ul li{
    list-style: none;
    margin: 5px;
  }
  .nav-links li a {
    font-size: 14px;
  }
  .footer{
    Width:100vw;
    background-color: var(--Blue);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color:var(--White);
    font-family: "Roboto", sans-serif;
  }
  .footerContext{
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mainHead{
    background-color: #e39168fe;
    width:80vw;
    height:40vh;
  }
  .container2{
    display: flex;
    flex-direction: column;
    margin:100px 0;
    justify-content: center;
    align-items: center;
  }
.container21{
  width:80vw;
  height:10vw;
}
.container22{
  width:80vw;
  background-color: var(--White1);
  height:400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.conatiner4{
  width: 100vw;
  padding:10px;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #dddddd4e;
}
.container41{
  padding:0;
  width: 100vw;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.container41Text{
  width:90vw;
}
.conatiner42{
  width: 0;
}
.container5{
  display: flex;
  flex-direction: column-reverse;
}
}